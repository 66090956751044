import React from 'react';
import styled from "styled-components";
import video from "./images/video.webm";
import bg from "./images/windows-bg.jpg";

const Desktop = () => {
  return (
    <>
        <Background>
            <video autoPlay={true} loop={false} muted={true} playsInline={true}>
                <source src={video} />
            </video>
        </Background>
    </>
  );
};

const Background = styled.main `

    background: url(${bg}) ;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    min-height: 100vh;
    position: relative;
    opacity: 1;
    z-index: 1500;
    overflow: hidden;

    video {

        width: 100%;
        height: 100%;
        object-fit: cover;
        pointer-events: none;
    }
`;

export default Desktop;