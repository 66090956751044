import React from 'react';
import "./App.css";
import Desktop from './components/Desktop';
import Taskbar from './components/Taskbar';

const App = () => {
  return (
    <>
      <Desktop/>
      <Taskbar/>
    </>
  );
};

export default App;